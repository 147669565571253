var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayShow
    ? _c("main", [
        _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  { attrs: { id: "'breadcrumb0", to: { name: "Profile" } } },
                  [
                    _c("span", { attrs: { to: "" } }, [
                      _vm._v(_vm._s(_vm.$t("PROFILE.ACCOUNT")))
                    ])
                  ]
                )
              ],
              1
            ),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _c("span", { attrs: { to: "" } }, [
                _vm._v(_vm._s(_vm.$t("PROFILE.PROFILE")))
              ])
            ])
          ])
        ]),
        _c(
          "div",
          [
            _c("h1", [_vm._v(_vm._s(_vm.$t("PROFILE.PROFILE")))]),
            _c("div", { staticClass: "section-group" }, [
              _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "form row" }, [
                  _c("div", { staticClass: "form-group col-6" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("PROFILE.BASIC_INFO")))
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "username" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.USERNAME")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "username",
                            name: "username",
                            disabled: "disabled"
                          },
                          domProps: { value: _vm.cookieData.username }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "name" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.NAME")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.information.name,
                              expression: "information.name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "name",
                            name: "name",
                            disabled: ""
                          },
                          domProps: { value: _vm.information.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.information,
                                "name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "phone" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.PHONE")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.information.phone,
                              expression: "information.phone"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "phone",
                            name: "phone",
                            disabled: ""
                          },
                          domProps: { value: _vm.information.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.information,
                                "phone",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "email" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.EMAIL")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.information.email,
                              expression: "information.email"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "email",
                            id: "email",
                            name: "email",
                            disabled: ""
                          },
                          domProps: { value: _vm.information.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.information,
                                "email",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "role" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.ROLE")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "role",
                            name: "role",
                            disabled: "disabled"
                          },
                          domProps: { value: _vm.role }
                        })
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "form row" }, [
                  _c("div", { staticClass: "form-group col-6" }, [
                    _c("h4", { staticClass: "bold" }, [
                      _vm._v(_vm._s(_vm.$t("PROFILE.CREDIT")))
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "currecy" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.CURRENCY")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.information.currency,
                              expression: "information.currency"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "currecy",
                            name: "currecy",
                            disabled: "disabled"
                          },
                          domProps: { value: _vm.information.currency },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.information,
                                "currency",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-4 col-form-label",
                          staticStyle: { "font-weight": "bold" },
                          attrs: { for: "credit" }
                        },
                        [_vm._v(_vm._s(_vm.$t("PROFILE.CREDIT")))]
                      ),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.information.creditLimit,
                              expression: "information.creditLimit"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "credit",
                            name: "credit",
                            disabled: ""
                          },
                          domProps: { value: _vm.information.creditLimit },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.information,
                                "creditLimit",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "form row" }, [
                  _c("div", { staticClass: "form-group col-12" }, [
                    _c("div", { staticClass: "tab-section card col-md-12" }, [
                      _c(
                        "div",
                        { staticClass: "tab-control-panel card-header" },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "nav nav-tabs",
                              attrs: { role: "tablist" }
                            },
                            [
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link active",
                                    attrs: {
                                      href: "#tl-md1",
                                      id: "",
                                      "data-toggle": "tab",
                                      role: "tab",
                                      "aria-controls": "tl-tab-h1",
                                      "aria-selected": "true"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("GAME_TYPE.ALLIN_OR_FOLD")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      href: "#tl-md2",
                                      id: "",
                                      "data-toggle": "tab",
                                      role: "tab",
                                      "aria-controls": "ll-tab-md",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("GAME_TYPE.HOLDEM")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      href: "#tl-md3",
                                      id: "",
                                      "data-toggle": "tab",
                                      role: "tab",
                                      "aria-controls": "",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("GAME_TYPE.SIX_PLUS_HOLDEM")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      href: "#tl-md4",
                                      id: "",
                                      "data-toggle": "tab",
                                      role: "tab",
                                      "aria-controls": "",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("GAME_TYPE.PLO4")) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("li", { staticClass: "nav-item" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      href: "#tl-md5",
                                      id: "",
                                      "data-toggle": "tab",
                                      role: "tab",
                                      "aria-controls": "",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("GAME_TYPE.PLO5")) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "tab-content" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade show active",
                              attrs: {
                                id: "tl-md1",
                                role: "tabpanel",
                                "aria-labelledby": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row mb-0" }, [
                                _c("div", { staticClass: "col-6 pr-0" }, [
                                  _vm._m(0),
                                  _c("div", { staticClass: "row" }),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [_vm._v(" Status ")]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.flodStatus,
                                                  expression:
                                                    "information.flodStatus"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "statusflod1",
                                                name: "statusflod",
                                                value: "true"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.flodStatus,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "flodStatus",
                                                    "true"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold",
                                                attrs: { for: "=" }
                                              },
                                              [_vm._v(" Enable ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.flodStatus,
                                                  expression:
                                                    "information.flodStatus"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "statusflod2",
                                                name: "statusflod",
                                                value: "false"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.flodStatus,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "flodStatus",
                                                    "false"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold",
                                                attrs: { for: "" }
                                              },
                                              [_vm._v(" Disable ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.HOLD_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              ),
                                              _vm._v(" : ")
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .flodOurPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "flodOurPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.flodOurPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.GIVE_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              ),
                                              _vm._v(" : ")
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .flodHoldPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "flodHoldPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.flodHoldPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("div", { staticClass: "mb-4" }, [
                                      _c("h4", { staticClass: "bold" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                          )
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" })
                                    ]),
                                    _vm._l(_vm.flod_limit_list, function(
                                      t,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-12" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row"
                                                },
                                                [
                                                  _c("label", {
                                                    staticClass:
                                                      "col-1 col-form-label bold"
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-8" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.information
                                                                .flodLimitArr,
                                                            expression:
                                                              "information.flodLimitArr"
                                                          }
                                                        ],
                                                        staticClass: "chk",
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "allin_checkbox_" +
                                                            index,
                                                          disabled: true
                                                        },
                                                        domProps: {
                                                          value: t.tierValue,
                                                          checked: Array.isArray(
                                                            _vm.information
                                                              .flodLimitArr
                                                          )
                                                            ? _vm._i(
                                                                _vm.information
                                                                  .flodLimitArr,
                                                                t.tierValue
                                                              ) > -1
                                                            : _vm.information
                                                                .flodLimitArr
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.information
                                                                  .flodLimitArr,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  t.tierValue,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "flodLimitArr",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "flodLimitArr",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.information,
                                                                "flodLimitArr",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label bold",
                                                          attrs: {
                                                            for:
                                                              "allin_checkbox_" +
                                                              index
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(t.name) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: {
                                id: "tl-md2",
                                role: "tabpanel",
                                "aria-labelledby": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row mb-0" }, [
                                _c("div", { staticClass: "col-6 pr-0" }, [
                                  _vm._m(1),
                                  _c("div", { staticClass: "row" }),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [_vm._v(" Status ")]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information
                                                      .holdemStatus,
                                                  expression:
                                                    "information.holdemStatus"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "statudholdem1",
                                                name: "statusholdem",
                                                value: "true"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.holdemStatus,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "holdemStatus",
                                                    "true"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold",
                                                attrs: { for: "" }
                                              },
                                              [_vm._v(" Enable ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information
                                                      .holdemStatus,
                                                  expression:
                                                    "information.holdemStatus"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "statudholdem2",
                                                name: "statusholdem",
                                                value: "false"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.holdemStatus,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "holdemStatus",
                                                    "false"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold",
                                                attrs: { for: "" }
                                              },
                                              [_vm._v(" Disable ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.HOLD_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .holdemOurPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "holdemOurPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.holdemOurPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.GIVE_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .holdemHoldPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "holdemHoldPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.holdemHoldPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [_vm._v(" Bet on Flop ")]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.holdemBOF,
                                                  expression:
                                                    "information.holdemBOF"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "status_holdem_bof1",
                                                name: "status_holdem_bof",
                                                value: "true"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.holdemBOF,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "holdemBOF",
                                                    "true"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold",
                                                attrs: { for: "" }
                                              },
                                              [_vm._v(" Enable ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.holdemBOF,
                                                  expression:
                                                    "information.holdemBOF"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "status_holdem_bof2",
                                                name: "status_holdem_bof",
                                                value: "false"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.holdemBOF,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "holdemBOF",
                                                    "false"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold",
                                                attrs: { for: "" }
                                              },
                                              [_vm._v(" Disable ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("div", { staticClass: "mb-4" }, [
                                      _c("h4", { staticClass: "bold" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                          )
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" })
                                    ]),
                                    _vm._l(_vm.holdem_limit_list, function(
                                      t,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-12" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row"
                                                },
                                                [
                                                  _c("label", {
                                                    staticClass:
                                                      "col-1 col-form-label bold"
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-8" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.information
                                                                .holdemLimitArr,
                                                            expression:
                                                              "information.holdemLimitArr"
                                                          }
                                                        ],
                                                        staticClass: "chk",
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "holdem_checkbox_" +
                                                            index,
                                                          disabled: true
                                                        },
                                                        domProps: {
                                                          value: t.tierValue,
                                                          checked: Array.isArray(
                                                            _vm.information
                                                              .holdemLimitArr
                                                          )
                                                            ? _vm._i(
                                                                _vm.information
                                                                  .holdemLimitArr,
                                                                t.tierValue
                                                              ) > -1
                                                            : _vm.information
                                                                .holdemLimitArr
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.information
                                                                  .holdemLimitArr,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  t.tierValue,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "holdemLimitArr",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "holdemLimitArr",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.information,
                                                                "holdemLimitArr",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label bold",
                                                          attrs: {
                                                            for:
                                                              "holdem_checkbox_" +
                                                              index
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(t.name) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: {
                                id: "tl-md3",
                                role: "tabpanel",
                                "aria-labelledby": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row mb-0" }, [
                                _c("div", { staticClass: "col-6 pr-0" }, [
                                  _vm._m(2),
                                  _c("div", { staticClass: "row" }),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [_vm._v(" Status ")]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information
                                                      .sixholdemStatus,
                                                  expression:
                                                    "information.sixholdemStatus"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "sixholdemstatus1",
                                                name: "sixholdemstatus",
                                                value: "true"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information
                                                    .sixholdemStatus,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "sixholdemStatus",
                                                    "true"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold"
                                              },
                                              [_vm._v(" Enable ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information
                                                      .sixholdemStatus,
                                                  expression:
                                                    "information.sixholdemStatus"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "sixholdemstatus2",
                                                name: "sixholdemstatus",
                                                value: "false"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information
                                                    .sixholdemStatus,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "sixholdemStatus",
                                                    "false"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold"
                                              },
                                              [_vm._v(" Disable ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.HOLD_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .sixholdemOurPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "sixholdemOurPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.sixholdemOurPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.GIVE_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  ),
                                                  disabled: true
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .sixholdemHoldPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "sixholdemHoldPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.sixholdemHoldPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("div", { staticClass: "mb-4" }, [
                                      _c("h4", { staticClass: "bold" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                          )
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" })
                                    ]),
                                    _vm._l(
                                      _vm.sixplus_holdemlimit_list,
                                      function(t, index) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-sm-12" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-group row"
                                                  },
                                                  [
                                                    _c("label", {
                                                      staticClass:
                                                        "col-1 col-form-label bold"
                                                    }),
                                                    _c(
                                                      "div",
                                                      { staticClass: "col-8" },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.information
                                                                  .sixholdemLimitArr,
                                                              expression:
                                                                "information.sixholdemLimitArr"
                                                            }
                                                          ],
                                                          staticClass: "chk",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "sixholdem_checkbox_" +
                                                              index,
                                                            disabled: true
                                                          },
                                                          domProps: {
                                                            value: t.tierValue,
                                                            checked: Array.isArray(
                                                              _vm.information
                                                                .sixholdemLimitArr
                                                            )
                                                              ? _vm._i(
                                                                  _vm
                                                                    .information
                                                                    .sixholdemLimitArr,
                                                                  t.tierValue
                                                                ) > -1
                                                              : _vm.information
                                                                  .sixholdemLimitArr
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm
                                                                    .information
                                                                    .sixholdemLimitArr,
                                                                $$el =
                                                                  $event.target,
                                                                $$c = $$el.checked
                                                                  ? true
                                                                  : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v =
                                                                    t.tierValue,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      _vm.information,
                                                                      "sixholdemLimitArr",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      _vm.information,
                                                                      "sixholdemLimitArr",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  _vm.information,
                                                                  "sixholdemLimitArr",
                                                                  $$c
                                                                )
                                                              }
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "form-check-label bold",
                                                            attrs: {
                                                              for:
                                                                "sixholdem_checkbox_" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(t.name) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      }
                                    )
                                  ],
                                  2
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: {
                                id: "tl-md4",
                                role: "tabpanel",
                                "aria-labelledby": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row mb-0" }, [
                                _c("div", { staticClass: "col-6 pr-0" }, [
                                  _vm._m(3),
                                  _c("div", { staticClass: "row" }),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [_vm._v(" Status ")]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.plo4Status,
                                                  expression:
                                                    "information.plo4Status"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "plo4status1",
                                                name: "plo4status",
                                                value: "true"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.plo4Status,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "plo4Status",
                                                    "true"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold"
                                              },
                                              [_vm._v(" Enable ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.plo4Status,
                                                  expression:
                                                    "information.plo4Status"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "plo4status2",
                                                name: "plo4status",
                                                value: "false"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.plo4Status,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "plo4Status",
                                                    "false"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold"
                                              },
                                              [_vm._v(" Disable ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.HOLD_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  disabled: true,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .plo4OurPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "plo4OurPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.plo4OurPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.GIVE_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "",
                                                  name: "",
                                                  clearable: false,
                                                  disabled: true,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .plo4HoldPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "plo4HoldPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.plo4HoldPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("div", { staticClass: "mb-4" }, [
                                      _c("h4", { staticClass: "bold" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                          )
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" })
                                    ]),
                                    _vm._l(_vm.plo4_limit_list, function(
                                      t,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-12" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row"
                                                },
                                                [
                                                  _c("label", {
                                                    staticClass:
                                                      "col-1 col-form-label bold"
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-8" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.information
                                                                .plo4LimitArr,
                                                            expression:
                                                              "information.plo4LimitArr"
                                                          }
                                                        ],
                                                        staticClass: "chk",
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "plo4_checkbox_" +
                                                            index,
                                                          disabled: true
                                                        },
                                                        domProps: {
                                                          value: t.tierValue,
                                                          checked: Array.isArray(
                                                            _vm.information
                                                              .plo4LimitArr
                                                          )
                                                            ? _vm._i(
                                                                _vm.information
                                                                  .plo4LimitArr,
                                                                t.tierValue
                                                              ) > -1
                                                            : _vm.information
                                                                .plo4LimitArr
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.information
                                                                  .plo4LimitArr,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  t.tierValue,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "plo4LimitArr",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "plo4LimitArr",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.information,
                                                                "plo4LimitArr",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label bold",
                                                          attrs: {
                                                            for:
                                                              "plo4_checkbox_" +
                                                              index
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(t.name) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-pane fade",
                              attrs: {
                                id: "tl-md5",
                                role: "tabpanel",
                                "aria-labelledby": ""
                              }
                            },
                            [
                              _c("div", { staticClass: "row mb-0" }, [
                                _c("div", { staticClass: "col-6 pr-0" }, [
                                  _vm._m(4),
                                  _c("div", { staticClass: "row" }),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [_vm._v(" Status ")]
                                          ),
                                          _c("div", { staticClass: "col-8" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.plo5Status,
                                                  expression:
                                                    "information.plo5Status"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "plo5status1",
                                                name: "plo5status",
                                                value: "true"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.plo5Status,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "plo5Status",
                                                    "true"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold"
                                              },
                                              [_vm._v(" Enable ")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.information.plo5Status,
                                                  expression:
                                                    "information.plo5Status"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: {
                                                type: "radio",
                                                id: "plo5status2",
                                                name: "plo5status",
                                                value: "false"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.information.plo5Status,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.$set(
                                                    _vm.information,
                                                    "plo5Status",
                                                    "false"
                                                  )
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "form-check-label bold"
                                              },
                                              [_vm._v(" Disable ")]
                                            )
                                          ])
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.HOLD_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  disabled: true,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .plo5OurPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "plo5OurPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.plo5OurPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-sm-12" }, [
                                      _c(
                                        "div",
                                        { staticClass: "form-group row" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "col-4 col-form-label bold"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "MANAGEMENT.GIVE_PERCENTAGE"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "required" },
                                                [_vm._v("★")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "col-8" },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  id: "myPercentage",
                                                  name: "myPercentage",
                                                  clearable: false,
                                                  disabled: true,
                                                  placeholder: _vm.$t(
                                                    "MANAGEMENT.ENTER_PERCENTAGE"
                                                  )
                                                },
                                                model: {
                                                  value:
                                                    _vm.information
                                                      .plo5HoldPercentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.information,
                                                      "plo5HoldPercentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "information.plo5HoldPercentage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-sm-6" },
                                  [
                                    _c("div", { staticClass: "mb-4" }, [
                                      _c("h4", { staticClass: "bold" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("MANAGEMENT.LIMIT_SETTING")
                                          )
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" })
                                    ]),
                                    _vm._l(_vm.plo5_limit_list, function(
                                      t,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-sm-12" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row"
                                                },
                                                [
                                                  _c("label", {
                                                    staticClass:
                                                      "col-1 col-form-label bold"
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-8" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.information
                                                                .plo5LimitArr,
                                                            expression:
                                                              "information.plo5LimitArr"
                                                          }
                                                        ],
                                                        staticClass: "chk",
                                                        attrs: {
                                                          type: "checkbox",
                                                          id:
                                                            "plo5_checkbox_" +
                                                            index,
                                                          disabled: true
                                                        },
                                                        domProps: {
                                                          value: t.tierValue,
                                                          checked: Array.isArray(
                                                            _vm.information
                                                              .plo5LimitArr
                                                          )
                                                            ? _vm._i(
                                                                _vm.information
                                                                  .plo5LimitArr,
                                                                t.tierValue
                                                              ) > -1
                                                            : _vm.information
                                                                .plo5LimitArr
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.information
                                                                  .plo5LimitArr,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v =
                                                                  t.tierValue,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "plo5LimitArr",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm.information,
                                                                    "plo5LimitArr",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.information,
                                                                "plo5LimitArr",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "form-check-label bold",
                                                          attrs: {
                                                            for:
                                                              "plo5_checkbox_" +
                                                              index
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(t.name) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _vm.role == "Agent"
                ? _c("div", { staticClass: "mb-4" }, [
                    _c("div", { staticClass: "form row" }, [
                      _c("div", { staticClass: "form-group col-6" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("PROFILE.INTREGRATE")))
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-4 col-form-label",
                              staticStyle: { "font-weight": "bold" },
                              attrs: { for: "PositionType" }
                            },
                            [_vm._v(_vm._s(_vm.$t("PROFILE.POSITION")))]
                          ),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "PositionType",
                                name: "PositionType",
                                disabled: "disabled"
                              },
                              domProps: { value: _vm.information.typeAgent }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-4 col-form-label",
                              staticStyle: { "font-weight": "bold" },
                              attrs: { for: "BetType" }
                            },
                            [_vm._v(_vm._s(_vm.$t("PROFILE.BET_TYPE")))]
                          ),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "BetType",
                                name: "BetType",
                                disabled: "disabled"
                              },
                              domProps: { value: _vm.information.typeAgentAPI }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-4 col-form-label",
                              staticStyle: { "font-weight": "bold" },
                              attrs: { for: "keyApi" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PROFILE.KEY_API")) + " "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "col-8" }, [
                            _c("div", { staticClass: "input-group mb-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.information.certificateKey,
                                    expression: "information.certificateKey"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "certificateKey",
                                  name: "certificateKey",
                                  readonly: ""
                                },
                                domProps: {
                                  value: _vm.information.certificateKey
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.information,
                                      "certificateKey",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("label", { staticClass: "col-4 col-form-label" }),
                          _c(
                            "div",
                            { staticClass: "col-8" },
                            [
                              _c("center", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-yellow ripple-parent mr-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.openGenerateAPIKeyModal()
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa-plus",
                                      staticStyle: { color: "white" }
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("PROFILE.GENERATE_API_KEY")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-4 col-form-label",
                              staticStyle: { "font-weight": "bold" },
                              attrs: { for: "callbackUrl" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("PROFILE.CALLBACK_URL")) +
                                  " "
                              )
                            ]
                          ),
                          _c("div", { staticClass: "col-7" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.information.urlCallback,
                                  expression: "information.urlCallback"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "callbackUrl",
                                required: "",
                                name: "callbackUrl",
                                disabled:
                                  _vm.subAccountMenus.profile || _vm.mainUser
                                    ? false
                                    : true
                              },
                              domProps: { value: _vm.information.urlCallback },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.information,
                                    "urlCallback",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "text-muted form-text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PROFILE.EXAMPLE")) +
                                    " : https://www.yourcallback.com/callback "
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-1" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-yellow ripple-parent mr-2",
                                attrs: {
                                  id: "add-callbackUrl",
                                  disabled:
                                    _vm.subAccountMenus.profile || _vm.mainUser
                                      ? false
                                      : true
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sendCallbackUrl()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PROFILE.BUTTON.SAVE")) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            {
                              staticClass: "col-4 col-form-label",
                              staticStyle: { "font-weight": "bold" },
                              attrs: { for: "ipWhiteList" }
                            },
                            [_vm._v(_vm._s(_vm.$t("PROFILE.WHITELIST_IP")))]
                          ),
                          _c("div", { staticClass: "col-7" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.information.ipWhitelist,
                                  expression: "information.ipWhitelist"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                id: "ipWhiteList",
                                name: "ipWhiteList",
                                disabled:
                                  _vm.subAccountMenus.profile || _vm.mainUser
                                    ? false
                                    : true
                              },
                              domProps: { value: _vm.information.ipWhitelist },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.information,
                                    "ipWhitelist",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "small",
                              { staticClass: "text-muted form-text" },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("PROFILE.EXAMPLE")) +
                                    " : 192.168.0.1"
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "col-1" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-yellow ripple-parent mr-2",
                                attrs: {
                                  id: "add-whitelist",
                                  disabled:
                                    _vm.subAccountMenus.profile || _vm.mainUser
                                      ? false
                                      : true
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.sendWhiteListIP()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("PROFILE.BUTTON.SAVE")) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "form-group col-6" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("PROFILE.WHITELIST_IP")))
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "table-responsive",
                            staticStyle: { width: "80%", "margin-left": "20%" }
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass: "table table-bordered table-hover"
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("PROFILE.IP")))
                                    ]),
                                    _c("th", [
                                      _vm._v(_vm._s(_vm.$t("PROFILE.ACTION")))
                                    ])
                                  ])
                                ]),
                                _vm.whiteListDataLoading
                                  ? _c("tbody", [_vm._m(5)])
                                  : _c(
                                      "tbody",
                                      [
                                        _vm.dataWhiteList.length == 0
                                          ? _c("tr", [
                                              _c(
                                                "td",
                                                { attrs: { colspan: "11" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("DATA_NOT_FOUND")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._l(_vm.dataWhiteList, function(
                                          list,
                                          key
                                        ) {
                                          return _c("tr", { key: key }, [
                                            _c("td", [_vm._v(_vm._s(list))]),
                                            _c("td", [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-danger mr-2",
                                                  attrs: {
                                                    type: "button",
                                                    id:
                                                      "delete-whitelist" + key,
                                                    "data-toggle": "modal",
                                                    "data-target":
                                                      "#modalDeleteWhiteList",
                                                    disabled:
                                                      _vm.subAccountMenus
                                                        .profile || _vm.mainUser
                                                        ? false
                                                        : true
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showModalDelete(
                                                        list,
                                                        key
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "PROFILE.BUTTON.DELETE"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ])
                                        })
                                      ],
                                      2
                                    )
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "table-responsive" }, [
                    _c(
                      "table",
                      { staticClass: "table table-bordered table-hover" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v(_vm._s(_vm.$t("PROFILE.NO")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("PROFILE.DATE")))]),
                            _c("th", [_vm._v(_vm._s(_vm.$t("PROFILE.IP")))])
                          ])
                        ]),
                        _vm.historyDataTbLoading
                          ? _c("tbody", [_vm._m(6)])
                          : _c(
                              "tbody",
                              [
                                _vm.dataLoginHistory.length == 0
                                  ? [
                                      _c("tr", [
                                        _c("td", { attrs: { colspan: "11" } }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("DATA_NOT_FOUND"))
                                          )
                                        ])
                                      ])
                                    ]
                                  : _vm._l(_vm.dataLoginHistory, function(
                                      r,
                                      index
                                    ) {
                                      return _c("tr", { key: r.id }, [
                                        _c("td", [_vm._v(_vm._s(index + 1))]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateTime")(r.lastLogin)
                                            )
                                          )
                                        ]),
                                        _c("td", [_vm._v(_vm._s(r.ipAddress))])
                                      ])
                                    })
                              ],
                              2
                            )
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "modal fade",
                attrs: {
                  id: "modalDeleteWhiteList",
                  tabindex: "-1",
                  role: "dialog",
                  "aria-labelledby": "modalDeleteWhiteListTitle",
                  "aria-hidden": "true"
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "modal-dialog modal-dialog-centered",
                    attrs: { role: "document" }
                  },
                  [
                    _c("div", { staticClass: "modal-content" }, [
                      _vm._m(7),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("p", [
                          _vm._v(
                            "คุณต้องการลบ Whitelist ip : " +
                              _vm._s(_vm.dataModalDelete.list)
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-danger",
                            on: {
                              click: function($event) {
                                return _vm.deleteWhiteList(
                                  _vm.dataModalDelete.key
                                )
                              }
                            }
                          },
                          [_vm._v("Confirm")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: {
                              type: "button",
                              id: "modal-close",
                              "data-dismiss": "modal"
                            }
                          },
                          [_vm._v("Close")]
                        )
                      ])
                    ])
                  ]
                )
              ]
            ),
            _c(
              "modal",
              { attrs: { name: "generateAPIKeyModal", clickToClose: false } },
              [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.$t("PROFILE.SECRET_KEY")) + " ")
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", id: "close-sharePercent" },
                      on: {
                        click: function($event) {
                          return _vm.hide("generateAPIKey")
                        }
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "modal-body" }, [
                  _vm.generateKeyModalLoading
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "col-12",
                            staticStyle: { "text-align": "center" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "spinner-border",
                                staticStyle: { color: "cornflowerblue" },
                                attrs: { role: "status" }
                              },
                              [
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Loading...")
                                ])
                              ]
                            )
                          ]
                        )
                      ])
                    : _c("div", [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-12",
                              staticStyle: { "text-align": "center" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PROFILE.TEXT1")) + " "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-12",
                              staticStyle: { "text-align": "center" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("PROFILE.TEXT2")) + " "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.information.completeSecretApiKey,
                                  expression: "information.completeSecretApiKey"
                                }
                              ],
                              staticClass: "form-control",
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                type: "text",
                                name: "apiKey",
                                readonly: "readonly"
                              },
                              domProps: {
                                value: _vm.information.completeSecretApiKey
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.information,
                                    "completeSecretApiKey",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("center", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard",
                                        value:
                                          _vm.information.completeSecretApiKey,
                                        expression:
                                          "information.completeSecretApiKey"
                                      }
                                    ],
                                    staticClass:
                                      "btn btn-yellow ripple-parent mr-2",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleMessage(
                                          "generateAPIKey"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("PROFILE.BUTTON.COPY")) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ])
                      ])
                ])
              ]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("h4", { staticClass: "bold" }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group row" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "7" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "7" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title",
          attrs: { id: "exampleModalDeleteWhiteListTitle" }
        },
        [_vm._v("Delete Whitelist")]
      ),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }