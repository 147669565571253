

















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import Profileomponent from "./Profile.component";
@Component
export default class Profile extends Mixins(Profileomponent) {}
