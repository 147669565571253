import { Component, Vue } from "vue-property-decorator";
import { VuejsDatatableFactory } from "vuejs-datatable";
import { GET_PROFILE_AGENT, GET_LOGIN_HISTORY_AGENT, UPDATE_CALLBACK, UPDATE_WHITELIST, DELETE_WHITELIST, GET_ROYALTY_SETTING_BY_ID, GENERATE_AGENT_KEY } from "../../../api/account"
import { FLOD_LIMIT_LIST, HOLDEM_LIMIT_LIST, SIXPLUS_HOLDEMLIMIT_LIST, PLO4_LIMIT_LIST, PLO5_LIMIT_LIST } from "../Agent/Component/gameTypeLimit.component"
import vSelect from 'vue-select';
import Model from "@/models/Model";
import moment from 'moment';
import VueClipboards from 'vue-clipboards';
import VModal from 'vue-js-modal'

import 'vue-select/dist/vue-select.css';
Vue.use(VuejsDatatableFactory);
Vue.use(VueClipboards);
Vue.use(VModal, { componentName: 'modal' })

@Component({
    components: {
        vSelect,
    },
    filters: {
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})

class Profile extends Vue {

    public displayShow = true
    public historyDataTbLoading = true;
    public whiteListDataLoading = true
    public generateKeyModalLoading = true;

    public role = ""
    public information = {
        name: "",
        phone: "",
        type: "",
        currency: "THB",
        certificateKey: "",
        completeSecretApiKey: "",
        creditLimit: 0,
        typeAgent : "",
        typeAgentAPI : "",
        urlCallback : "",
        ipWhitelist : "",
        limit : 0,
        ourPT: "0",
        email: "",
        username: "",
       
        flodStatus: false,
        flodOurPercentage: {},
        flodHoldPercentage: {},
        flodLimit: 0,
        flodLimitArr: [],

        holdemStatus: false,
        holdemOurPercentage: {},
        holdemHoldPercentage: {},
        holdemLimit: 0,
        holdemLimitArr: [],
        holdemBOF: false,

        sixholdemStatus: false,
        sixholdemOurPercentage: {},
        sixholdemHoldPercentage: {},
        sixholdemLimit: 0,
        sixholdemLimitArr: [],

        plo4Status: false,
        plo4OurPercentage: {},
        plo4HoldPercentage: {},
        plo4Limit: 0,
        plo4LimitArr: [],

        plo5Status: false,
        plo5OurPercentage: {},
        plo5HoldPercentage: {},
        plo5Limit: 0,
        plo5LimitArr: []
    }

    public dataModalDelete = {
        list: "",
        key: ""
    }

    public flod_limit_list = FLOD_LIMIT_LIST
    public holdem_limit_list = HOLDEM_LIMIT_LIST
    public sixplus_holdemlimit_list = SIXPLUS_HOLDEMLIMIT_LIST
    public plo4_limit_list = PLO4_LIMIT_LIST
    public plo5_limit_list = PLO5_LIMIT_LIST

    private royaltySetting

    public dataLoginHistory: any = []
    public dataWhiteList: any = []
    public cookieData: any = Vue.$cookies.get("login")
    private model: Model;
    public mainUser = true
    public subAccountMenus = {
        profile: false
    }

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.mainUser = false
            this.$store.state.profile.subAccountMenus.filter(val => {
                if (val.name == 'MENU_ACCOUNT') {
                    if (val.permission == 'readonly') {
                        this.subAccountMenus.profile = false
                    } else if (val.permission == 'editable') {
                        this.subAccountMenus.profile = true
                    }
                }
            });
        } else {
            this.mainUser = true
        }
        
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        
        await this.getRoyaltySetting()
        this.addRouteName()
        await this.getProfileData()
        await this.setRate()
        await this.getLoginHistory()
        loader.hide()
    }

    public async getRoyaltySetting() {
        this.royaltySetting = await GET_ROYALTY_SETTING_BY_ID(this.cookieData.realUsername)
        this.royaltySetting = this.royaltySetting[0].royaltySetting
    }  

    public findLocalGameTypeVariableName( objName: string){
        const localProductObj = { 
            allinorfold: { status: "flodStatus", ourPercent: "flodOurPercentage", holdPercent: "flodHoldPercentage", limit: "flodLimitArr"},
            holdem: { status: "holdemStatus", ourPercent: "holdemOurPercentage", holdPercent: "holdemHoldPercentage", limit: "holdemLimitArr"},
            sixplus: { status: "sixholdemStatus", ourPercent: "sixholdemOurPercentage", holdPercent: "sixholdemHoldPercentage", limit: "sixholdemLimitArr"},
            plo4: { status: "plo4Status", ourPercent: "plo4OurPercentage", holdPercent: "plo4HoldPercentage", limit: "plo4LimitArr"},
            plo5: { status: "plo5Status", ourPercent: "plo5OurPercentage", holdPercent: "plo5HoldPercentage", limit: "plo5LimitArr"},
        }
        for (const [key, value] of Object.entries(localProductObj)) {
            if(key == objName){
                return value
            }
        }
        return false
    }

    public async setRate() {
        for (const [key, productSetting] of Object.entries(this.royaltySetting.products)) {            
            const localValName = this.findLocalGameTypeVariableName(key)
            if(!localValName){
                continue
            }
            this.setEachProductSetting(localValName, productSetting)
        }
    }

    public setEachProductSetting(localValName: any, productSetting: any){
        this.information[localValName.status] = productSetting.enable
        this.information[localValName.ourPercent] = {value: productSetting.rate, label: `${productSetting.rate}%`};
        this.information[localValName.holdPercent] = {value: 0, label: `0%`};
        this.information[localValName.limit] = productSetting.limit
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    private async getLoginHistory() {
        this.historyDataTbLoading = true
        this.dataLoginHistory = await GET_LOGIN_HISTORY_AGENT(this.cookieData.username);
        this.historyDataTbLoading = false
    }

    private async getProfileData() {
        const rawProfile = await GET_PROFILE_AGENT(this.cookieData.realUsername)
        const data = rawProfile[0]
        
        this.role = data.roles 
        this.information.certificateKey = data.certificateKey
        this.information.name = data.name
        this.information.creditLimit = data.creditLimit
        this.information.currency = 'THB'
        this.information.urlCallback = data.urlCallback
        this.information.username = data.username

        this.dataWhiteList = data.whiteListApi
        this.whiteListDataLoading = false

        if (Object.prototype.hasOwnProperty.call(data, 'information')) {
            this.information.phone = data.information.contact.phone
            this.information.email = data.information.contact.email
        }

        this.information.typeAgent = data.typeAgent
        this.information.typeAgentAPI = data.typeAgentAPI

        this.information.holdemBOF = data.royaltySetting.products.holdem.miniGame?.includes("BOF") || false
    }

    public async sendWhiteListIP() {
        if (!(this.subAccountMenus.profile || this.mainUser)){
            return
        }

        let data = {}
        data = {
            username: this.cookieData.realUsername,
            whiteListApi: this.information.ipWhitelist
        }
        const resultUpdateWhiteList = await UPDATE_WHITELIST(data)
        if (resultUpdateWhiteList.success) {
            const textAlert = (this.$store.state.locale == 'th-TH') ? 'เพิ่ม Whitelist สำเร็จ' : 'Whitelist was added successfully.'
            this.$swal({
                text: textAlert,
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/profile');
                }
            });
            return
        }

        return
    }

    public async openGenerateAPIKeyModal(){
        this.$modal.show('generateAPIKeyModal');
        this.generateKeyModalLoading = true
        
        const resultKey = await GENERATE_AGENT_KEY(this.information);
        if (resultKey.success) {

            this.information.completeSecretApiKey = resultKey.data.uncensor
            this.information.certificateKey = resultKey.data.censor
            this.generateKeyModalLoading = false
            
        } else {

            this.information.completeSecretApiKey = ""
            this.information.certificateKey = ""
            this.displayAlertSwal(resultKey.error.message)

        }
    }

    public hide(field: string) {
        switch (field) {
            case 'generateAPIKey':
                this.$modal.hide('generateAPIKeyModal');
                break;
        }
    }

    public async sendCallbackUrl() {
        if (!(this.subAccountMenus.profile || this.mainUser)) {
            return
        }

        let data = {}
        const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
        const urlCheck = this.information.urlCallback.match(urlRegex);
        if (!urlCheck) {
            this.$swal({
                text: `${this.$t('PROFILE.CHECK_CALLBACK').toString()}`,
                icon: "error",
                confirmButtonColor: '#CFA137',
                confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
            });
            return;
        } 
        
        data = {
            username: this.cookieData.realUsername,
            callbackUrl: this.information.urlCallback
        }

        const resultUpdateCallback = await UPDATE_CALLBACK(data)
        if (resultUpdateCallback.success) {
            this.$swal({
                text: `${this.$t('PROFILE.CALLBACK_SUCCESS').toString()}`,
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/profile');
                }
            });
            return
        }
        
        return
    }

    public showModalDelete(list, key) {
        this.dataModalDelete.list = list
        this.dataModalDelete.key = key
    }

    public async deleteWhiteList(key) {
        let data = {}
        data = {
            username: this.cookieData.realUsername,
            index: key
        }
        const resultDeleteWhiteList = await DELETE_WHITELIST(data)
        if (resultDeleteWhiteList.success) {
            const textAlert = (this.$store.state.locale == 'th-TH') ? 'ลบ Whitelist สำเร็จ' : 'Whitelist deleted successfully.'
            this.$swal({
                text: textAlert,
                icon: "success",
                timer: 2000,
                showConfirmButton: false,
                timerProgressBar: true,
                onClose: () => {
                    window.location.replace('/profile');
                }
            });
        }
    }

    public handleMessage(messageType: string) {
        let textAlert = ""
        if( messageType == "generateAPIKey"){
            textAlert = (this.$store.state.locale == 'th-TH') ? 'คุณพึ่งคัดลอก: ' : 'You just copied: '
            textAlert += this.information.completeSecretApiKey
        }
        
        this.$swal({
            title: "Copied",
            text: textAlert,
            icon: "success",
            confirmButtonColor: '#CFA137',
            confirmButtonText: "<span style='color: white;'>OK</span>",
        }).then((value) => {
            if (value) {
                console.log("OK")
            }
        });
    }

    public displayAlertSwal = (message?: string) => {

        let textAlert = ""
        if (message) {
            textAlert = message
        }

        this.$swal({
            text: textAlert,
            icon: "error",
            confirmButtonColor: '#CFA137',
            confirmButtonText: `<span style='color: white;'>${this.$t('OK').toString()}</span>`
        });

        return
        
    }

}
export default Profile